<template>
  <Btn @click="$emit('click')" class="bouton-drapeau-usa" :class="{'btn': btn !== undefined}"/>
</template>

<style lang="scss" scoped>
.bouton-drapeau-usa.btn {
  cursor: pointer;
  user-select: none;

  .rouge {
    fill: #e71225;
  }

  &:hover .rouge {
    fill: lighten(#e71225, 10%);
  }

  &:active .rouge {
    fill: #e71225;
  }

  .bleu {
    fill: #004f8b;
  }

  &:hover .bleu {
    fill: lighten(#004f8b, 10%);
  }

  &:active .bleu {
    fill: darken(#004f8b, 5%);
  }


  &:active .blanc {
    fill: darken(#E1E1E1, 5%);
  }

  .contour {
    fill: #000;
  }

  &:hover .contour {
    fill: #3b3b3b;
  }

  &:active .contour {
    fill: #000;
  }
}
</style>

<script>
export default {
  name: 'IconeUsa',
  components: {
    Logo: () => import('./inline.pays_usa.svg'),
  },
}
</script>
